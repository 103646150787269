import * as AutoKana from 'vanilla-autokana';

document.addEventListener("DOMContentLoaded", function() {
  autokanaBind();
});

function autokanaBind() {
  // ============================================================
  // お名前（漢字）とお名前（フリガナ）の自動入力
  // ============================================================
  
  // 現在のURLを取得
  let currentUrl = window.location.href;
  let idToBindTrigger;
  let idToBindDist;
  let idToBindTrigger02;
  let idToBindDist02;
  
//   URLにthanks.htmlが含まれる場合は処理を終了
  if (currentUrl.includes('thanks')) {
    return;
  }
  
// URLに基づいてオートカナを適用する要素のIDを決定
  if (currentUrl.includes(window.location.origin + '/start/')&& !currentUrl.includes('?step=reapply')&& !currentUrl.includes('thanks')) {
    idToBindTrigger = "#field_start_kanji_name";
    idToBindDist = "#field_start_furigana_name";
    
  } else if (currentUrl.includes(window.location.origin + '/stop/')&& !currentUrl.includes('?step=reapply')&& !currentUrl.includes('thanks')) {
    idToBindTrigger = "#field_stop_kanji_name";
    idToBindDist = "#field_stop_furigana_name";
    
  } else if (currentUrl.includes(window.location.origin + '/startstop/')&& !currentUrl.includes('?step=reapply')&& !currentUrl.includes('thanks')) {
    idToBindTrigger = "#field_start_kanji_name";
    idToBindDist = "#field_start_furigana_name";
    idToBindTrigger02 = "#field_stop_kanji_name";
    idToBindDist02 = "#field_stop_furigana_name";
  }
  
  // AutoKanaを適用（idToBind と idToBindDist が存在する場合のみ）
  if (idToBindTrigger && idToBindDist) {
    AutoKana.bind(idToBindTrigger, idToBindDist, { katakana: true });
  }
  // AutoKanaを適用（idToBind 02と idToBindDist 02が存在する場合のみ）
  if (idToBindTrigger02 && idToBindDist02) {
    AutoKana.bind(idToBindTrigger02, idToBindDist02, { katakana: true });
  }
}

$(function (){

  // ============================================================
  // メールアドレス一致チェック
  // ============================================================

  let field_mail_text;
  let field_mail_check_text;
  let $field_mail = $('#field_mail');
  let $field_mail_check = $('#field_mail_check');
  let $js_checkMachEmail = $('#js_checkMachEmail');

  $field_mail.blur(function (){
    field_mail_text = $(this).val();
    mailAdressCheck();
  });

  $field_mail_check.blur(function (){
    field_mail_check_text = $(this).val();
    mailAdressCheck();
  });


  function mailAdressCheck(){
    if(field_mail_text && field_mail_check_text){
      if(field_mail_text === field_mail_check_text){
        $js_checkMachEmail.hide();
      }else{
        $js_checkMachEmail.show();
      }
    }else{
    }
  }
  
  // ------------------------------------------------------------
  // パスワード確認フィールドをコピペできないようにする
  // ------------------------------------------------------------
  
  function checkMailAdress(){
    let $elm = $('.js_checkPasteAlert');
    if($elm.length){
      $elm.on('paste', function(e) {
        e.preventDefault();
        alert('確認用のメールアドレスは貼り付けできません。');
      });
    }
  }
  checkMailAdress();
  
  // ------------------------------------------------------------
  // お客様番号の正規チェック
  // ------------------------------------------------------------
  
  function checkCsNumberFormat(){
    let $elm = $('.js_checkCsNumberFormat');
    if($elm.length){
      $elm.on('change',function (){
          var inputValue = $(this).val();
          var regex = /^(11|12|13|14|15|16|21|31|32|33|34|41|42|52|52)[0-9０-９]{9}$/;
          
          if (!regex.test(inputValue)) {
            alert('入力された値は正しい形式ではありません。');
            $elm.val('');
          }
      })
    }
  }
  checkCsNumberFormat();
  
  
  // ============================================================
  // 希望日
  // ============================================================

  let today = new Date();
  for (let i = 5; i <= 18; i++) {
    let futureDate = new Date();
    futureDate.setDate(today.getDate() + i);
    let dd = String(futureDate.getDate()).padStart(2, '0');
    let mm = String(futureDate.getMonth() + 1).padStart(2, '0');
    let yyyy = futureDate.getFullYear();
    let dateString = yyyy + '年' + mm + '月' + dd + "日";

    $('#field_start_date').append($('<option>', {
      value: dateString,
      text : dateString
    }));
    $('#field_stop_date').append($('<option>', {
      value: dateString,
      text : dateString
    }));
  }
    
  // ============================================================
  // TODO : 2/7まで実装希望：排他的必須チェック
  // ============================================================
  
  let flag_existence_start_building_type = $('input[name="field_start_building_type"]').length ? true : false;
  let flag_existence_stop_building_type = $('input[name="field_stop_building_type"]').length ? true : false;
  let flag_existence_stop_new_address_building_type = $('input[name="field_stop_new_address_building_type"]').length ? true : false;
  
  let str_start_building_type = flag_existence_start_building_type ? $('input[name="field_start_building_type"]').val() : undefined;
  let str_stop_building_type = flag_existence_stop_building_type ? $('input[name="field_stop_building_type"]').val() : undefined;
  let str_stop_new_address_building_type = flag_existence_stop_building_type ? $('input[name="field_stop_new_address_building_type"]').val() : undefined;
  // (集合住宅or戸建て) or undefined
  
  // ------------------------------------------------------------
  // DOMを取得
  // ------------------------------------------------------------
  
  let $field_start_building_name = $('input[name="field_start_building_name"]');
  let $field_start_building_wing = $('input[name="field_start_building_wing"]');
  let $field_start_room_number = $('input[name="field_start_room_number"]');
  
  let $field_stop_building_name = $('input[name="field_stop_building_name"]');
  let $field_stop_building_wing = $('input[name="field_stop_building_wing"]');
  let $field_stop_room_number = $('input[name="field_stop_room_number"]');
  
  let $field_stop_new_address_building_name = $('input[name="field_stop_new_address_building_name"]');
  let $field_stop_new_address_building_wing = $('input[name="field_stop_new_address_building_wing"]');
  let $field_stop_new_address_room_number = $('input[name="field_stop_new_address_room_number"]');
 
  // ------------------------------------------------------------
  //  フィールドの値を取得
  // ------------------------------------------------------------
  
  // 開栓の対象フィールドの値が格納される変数
  let has_data_fields_start_buildings = false;
  let val_field_start_building_name;
  let val_field_start_building_wing;
  let val_field_start_room_number;
  // 閉栓の対象フィールドの値が格納される変数
  let has_data_fields_stop_buildings = false;
  let val_field_stop_building_name
  let val_field_stop_building_wing
  let val_field_stop_room_number
  // 閉栓の転宅先住所の対象フィールドの値が格納される変数
  let has_data_fields_stop_new_address_buildings = false;
  let val_field_stop_new_address_building_name
  let val_field_stop_new_address_building_wing
  let val_field_stop_new_address_room_number
  
  
  // -------------------
  // 値取得関数
  // -------------------
  function get_fieldes_data_start_buildings(){
    if(flag_existence_start_building_type){
      val_field_start_building_name = $field_start_building_name.val();
      val_field_start_building_wing = $field_start_building_wing.val();
      val_field_start_room_number = $field_start_room_number.val();
      has_data_fields_start_buildings = true;
    }
  }
  function get_fieldes_data_stop_buildings() {
    if(flag_existence_stop_building_type){
      val_field_stop_building_name = $field_stop_building_name.val();
      val_field_stop_building_wing = $field_stop_building_wing.val();
      val_field_stop_room_number = $field_stop_room_number.val();
      has_data_fields_stop_buildings = true;
    }
  }
  
  function get_fieldes_data_stop_new_address_buildings() {
    if(flag_existence_stop_new_address_building_type){
      val_field_stop_new_address_building_name = $field_stop_new_address_building_name.val();
      val_field_stop_new_address_building_wing = $field_stop_new_address_building_wing.val();
      val_field_stop_new_address_room_number = $field_stop_new_address_room_number.val();
      has_data_fields_stop_new_address_buildings = true;
    }
  }
  
  //  確認画面から入力画面に戻ってきた際に値を取得する必要があるため読み込み時実行
  //  入力画面からきた場合はフラグがfalseとなり、undefinedになる
  // -------------------
  get_fieldes_data_start_buildings();
  get_fieldes_data_stop_buildings();
  get_fieldes_data_stop_new_address_buildings();
  
  // ============================================================
  // 切り替え処理
  // ============================================================
  
  const TYPE_SHUGOU = "集合住宅";
  const TYPE_KODATE = "戸建住宅";
  const REQUIRED_ESCAPE_TEXT = "-";
  
  function toggleFieldBuilding(type) {
    // ------------------------------------------------------------
    // 開栓フォーム
    // ------------------------------------------------------------
    if (flag_existence_start_building_type && type === 'start'){
      // -------------------
      // 戸建て選択時
      // -------------------
      if (!(str_start_building_type === TYPE_KODATE)) {
        get_fieldes_data_start_buildings();
        $field_start_building_name.val(REQUIRED_ESCAPE_TEXT);
        $field_start_building_wing.val(REQUIRED_ESCAPE_TEXT);
        $field_start_room_number.val(REQUIRED_ESCAPE_TEXT);
        str_start_building_type = TYPE_KODATE;
        toggleExFiledGroup(false,'start');
        
      // -------------------
      // 集合住宅選択時
      // -------------------
      } else if (!(str_start_building_type === TYPE_SHUGOU)) {
        $field_start_building_name.val(val_field_start_building_name);
        $field_start_building_wing.val(val_field_start_building_wing);
        $field_start_room_number.val(val_field_start_room_number);
        str_start_building_type = $('input[name="field_start_building_type"]').val();
        str_start_building_type = TYPE_SHUGOU;
        toggleExFiledGroup(true,'start');
      }
    }
    
    // ------------------------------------------------------------
    // 閉栓フォーム
    // ------------------------------------------------------------
    
    if (flag_existence_stop_building_type && type === 'stop') {
      // -------------------
      // 戸建て選択時
      // -------------------
      if (!(str_stop_building_type === TYPE_KODATE)) {
        get_fieldes_data_stop_buildings();
        $field_stop_building_name.val(REQUIRED_ESCAPE_TEXT);
        $field_stop_building_wing.val(REQUIRED_ESCAPE_TEXT);
        $field_stop_room_number.val(REQUIRED_ESCAPE_TEXT);
        str_stop_building_type = TYPE_KODATE;
        toggleExFiledGroup(false,'stop');
        
        // -------------------
        // 集合住宅選択時
        // -------------------
      } else if (!(str_stop_building_type === TYPE_SHUGOU)) {
        $field_stop_building_name.val(val_field_stop_building_name);
        $field_stop_building_wing.val(val_field_stop_building_wing);
        $field_stop_room_number.val(val_field_stop_room_number);
        str_stop_building_type = $('input[name="field_stop_building_type"]').val();
        str_stop_building_type = TYPE_SHUGOU;
        toggleExFiledGroup(true,'stop');
      }
    }
    
    // ------------------------------------------------------------
    // 閉栓フォーム転居先住所
    // ------------------------------------------------------------
    
    if (flag_existence_stop_new_address_building_type && type === 'stop_new_address') {
      // -------------------
      // 戸建て選択時
      // -------------------
      if (!(str_stop_new_address_building_type === TYPE_KODATE)) {
        get_fieldes_data_stop_new_address_buildings();
        $field_stop_new_address_building_name.val(REQUIRED_ESCAPE_TEXT);
        $field_stop_new_address_building_wing.val(REQUIRED_ESCAPE_TEXT);
        $field_stop_new_address_room_number.val(REQUIRED_ESCAPE_TEXT);
        str_stop_new_address_building_type = TYPE_KODATE;
        console.log($field_stop_new_address_building_name);
        console.log($field_stop_new_address_building_wing);
        console.log($field_stop_new_address_room_number);
        toggleExFiledGroup(false,'stop_new_address');
        console.log(str_stop_new_address_building_type);
        
        // -------------------
        // 集合住宅選択時
        // -------------------
      } else if (!(str_stop_new_address_building_type === TYPE_SHUGOU)) {
        $field_stop_new_address_building_name.val(val_field_stop_new_address_building_name);
        $field_stop_new_address_building_wing.val(val_field_stop_new_address_building_wing);
        $field_stop_new_address_room_number.val(val_field_stop_new_address_room_number);
        str_stop_new_address_building_type = $('input[name="field_stop_new_address_building_type"]').val();
        console.log(val_field_stop_new_address_building_name);
        console.log(val_field_stop_new_address_building_wing);
        console.log(val_field_stop_new_address_room_number);
        str_stop_new_address_building_type = TYPE_SHUGOU;
        toggleExFiledGroup(true,'stop_new_address');
        console.log(str_stop_new_address_building_type);
      }
    }
  }
  
  // ============================================================
  //  表示非表示切り替え処理
  // ============================================================
  
  function toggleExFiledGroup(bool,type){
    if(bool === true){ //戸建て
      $('.field_group_building_type_' + type).next().show();
      $('.field_group_roomNumber_'  + type).show();
    }else{ //集合住宅
      $('.field_group_building_type_' + type).next().hide();
      $('.field_group_roomNumber_'  + type).hide();
    }
  }
  
  // ------------------------------------------------------------
  // 排他的フォームイベント処理
  // ------------------------------------------------------------
  $('input[name="field_start_building_type"]').on('change',function (){
    let type = $(this).closest('.field_group_building_type_start').data('type')
    toggleFieldBuilding(type);
  });
  $('input[name="field_stop_building_type"]').on('change',function (){
    let type = $(this).closest('.field_group_building_type_stop').data('type')
    toggleFieldBuilding(type);
  });
  $('input[name="field_stop_new_address_building_type"]').on('change',function (){
    let type = $(this).closest('.field_group_building_type_stop_new_address').data('type')
    toggleFieldBuilding(type);
  });
  
  // ============================================================
  // ページ読み込み時実行関数
  // ============================================================
  
  toggleExFiledGroup(true); //初期読み込み時実行
  
  // ------------------------------------------------------------
  // FIXME : 公開後実装：ローカルストレージにfieldの値をセット
  // ------------------------------------------------------------
  
  function setFieldDataLocalStorage(){
    //この関数内部で必要な値をローカルストレージにセット
  }
  
  // ------------------------------------------------------------
  // FIXME : 公開後実装：ローカルストレージにセットされている値をゲット
  // ------------------------------------------------------------
  
  function getFieldDataLocalStorage(){
    //この関数内部で必要な値をローカルストレージにゲット
  }
  
  // ------------------------------------------------------------
  // FIXME : 公開後実装：送信ボタン押下時イベント
  // ------------------------------------------------------------

  $('.fromSubmitBtn').on('click',function (){
    setFieldDataLocalStorage();
  });
  
  
  
  
  // ============================================================
  // バリデーション エラー箇所スクロール機能
  // ============================================================
  
  ACMS.Ready(function (){
    ACMS.addListener('acmsValidateFailed', (event) => {
      
      
      let $elm_0 = $('.validator-result-0')[0].closest('.formBoxTableData');
      let targetPositonY = $($elm_0).offset().top;
      let speed = 300;
      
      $('body,html').animate({scrollTop:targetPositonY}, speed, 'swing');
      
      
    });
  });
  
});

// ============================================================
// 電話番号フィールド結合処理
// ============================================================

//Jqueryにてfield_start-tel01,02,03を結合してsubmitボタンが押された瞬間にfield_start_telにセット
$(function (){
  $('.fromSubmitBtn').on('click', function (){
    
    if($('input[name="field_start_tel"]').length){
      let start_val01 = $('input[name="field_start_tel01"]').val();
      let start_val02 = $('input[name="field_start_tel02"]').val();
      let start_val03 = $('input[name="field_start_tel03"]').val();
      $('input[name="field_start_tel"]').val(start_val01 + '-' + start_val02 + '-' + start_val03);
    }
    if($('input[name="field_stop_tel"]').length){
      let stop_val01 = $('input[name="field_stop_tel01"]').val();
      let stop_val02 = $('input[name="field_stop_tel02"]').val();
      let stop_val03 = $('input[name="field_stop_tel03"]').val();
      $('input[name="field_stop_tel"]').val(stop_val01 + '-' + stop_val02 + '-' + stop_val03);
    }
  });
});

// ============================================================
// 希望日時選択フィールドの値を保持する
// ============================================================


$(function (){
  
  // 確認ボタンが押された場合に希望日時選択フィールドの値をローカルストレージにセット
  $('#btnConfirm').on('click', function (){
    let field_start_date = $('select[name="field_start_date"]').val();
    let field_stop_date = $('select[name="field_stop_date"]').val();
    localStorage.setItem('field_start_date', field_start_date);
    localStorage.setItem('field_stop_date', field_stop_date);
  });

  // 確認画面から戻るボタンを押して希望日時のappend処理が終わってからローカルストレージの値を取得、出力されたselect要素と照合わせてselectedを付与
  
  let field_start_date = localStorage.getItem('field_start_date');
  let field_stop_date = localStorage.getItem('field_stop_date');
  
  // 希望日の日付を取得してoption要素にセット
  let today = new Date();
  
  // for (let i = 5; i <= 18; i++) {
  //
  //   // let futureDate = new Date();
  //   // futureDate.setDate(today.getDate() + i);
  //   //
  //   // let dd = String(futureDate.getDate()).padStart(2, '0');
  //   // let mm = String(futureDate.getMonth() + 1).padStart(2, '0');
  //   // let yyyy = futureDate.getFullYear();
  //   // let dateString = yyyy + '年' + mm + '月' + dd + "日";
  //   //
  //   // if($('#field_start_date').length){
  //   //   $('#field_start_date').append($('<option>', {
  //   //     value: dateString,
  //   //     text: dateString
  //   //   }));
  //   // }
  //
  //   if($('#field_stop_date').length) {
  //     $('#field_stop_date').append($('<option>', {
  //       value: dateString,
  //       text: dateString
  //     }));
  //   }
  // }
  
  // let selectIsMatch = false;
  
  
  // 開始日に一致するオプションを選択
  if ($('select[name="field_start_date"] option[value="' + field_start_date + '"]').length) {
    $('select[name="field_start_date"]').val(field_start_date);
  } else {
    // 一致するものがない場合の処理（必要に応じて）
  }
  
  // 終了日に一致するオプションを選択
  if ($('select[name="field_stop_date"] option[value="' + field_stop_date + '"]').length) {
    $('select[name="field_stop_date"]').val(field_stop_date);
  } else {
    // 一致するものがない場合の処理（必要に応じて）
  }
  // $('#field_start_date option').each(function() {
  //
  //   if ($(this).val() === field_start_date) {
  //     selectIsMatch = true;
  //     return false; // ループから抜け出す
  //   }
  // });
  //
  // if (selectIsMatch) {
  //   // 開栓の希望日に一致するオプションを選択状態にする
  //   $('#field_start_date').val(field_start_date);
  // } else {
  //   // 一致するものがない場合の処理（必要に応じて）
  //   return
  // }
  //
  // $('#field_stop_date option').each(function() {
  //   if ($(this).val() === field_stop_date) {
  //     selectIsMatch = true;
  //     return false;// ループから抜け出す
  //   }
  // });
  //
  
  
  
  // if (selectIsMatch) {
  //   // 開栓の希望日に一致するオプションを選択状態にする
  //   $('#field_stop_date').val(field_stop_date);
  //   alert ('一致しました')
  // } else {
  //   // 一致するものがない場合の処理（必要に応じて）
  // }

  
});
